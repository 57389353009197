html,
body {
    font-size: 0.82em !important;
    line-height: 1;
}

.full-height {
    height: calc(100vh - 80px);
    max-width: calc(100vw - 5px);
}

td {
    vertical-align: middle !important;
}

.right {
    text-align: right !important;
}

.center {
    text-align: center !important;
}

.whole-cell {
    display: block !important;
    padding: 0px 0px !important;
}

input.ng-invalid,
select.ng-invalid,
.p-inputwrapper.ng-invalid,
.k-input.ng-invalid {
    border-left: 2px solid red;
}

.form-select.ng-invalid {
    border-left: 2px solid red;
}

.btn-group.ng-invalid {
    border: 2px solid red;
}

.right-align {
    text-align: right !important;
}

.right-border {
    border-right: 1px solid #000;
    background-color: #eee;
}

.right {
    text-align: right
}

.ccy {
    max-width: 40% !important;
}

.form-control:disabled {
    background-color: #bdb8b8 !important;
}

button {
    border-radius: 0 !important;
}

.form-group {
    margin-bottom: .2rem !important;
}

.k-input:disabled {
    background-color: #d3d0d0 !important;
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.nav-tabs {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

input.ng-invalid,
select.ng-invalid,
.k-input.ng-invalid {
    border-left: 2px solid red;
}

.btn-group.ng-invalid {
    border: 2px solid red;
}

.absolute-center {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
}

input,
select,
button,
label {
    font-size: 1em !important;
}



tbody,
td,
tfoot,
th,
thead,
tr {
    font-size: 0.85rem !important;
}

.badge {
    font-size: 1em !important;
}

.btn {
    border-radius: 0px !important;
}

/* prime ng */

/* .p-inputtext {
     font-size: 0.85em !important;
     padding: 0.5rem 0.5rem !important;
     border-radius: 0px !important;
 } */

/* .p-datatable .p-datatable-tbody>tr>td {
    padding: .15rem .15rem !important;
} */

.p-button.p-button-icon-only {
    width: 2rem !important;
    padding: 0.35rem 0.35rem !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    font-size: 0.90rem !important;
}

.p-button {
    border-radius: 0px !important;
    padding: 0.35rem 0.35rem !important;
}

.p-inputtext {
    padding: .30rem .30rem !important;
    border-radius: 0px !important;
}

.p-dropdown {
    border-radius: 0px !important;
}

input,
select,
button,
label {
    font-size: 0.90rem !important;
}

.p-selectbutton .p-button.p-highlight.p-button-success-dummy {
    background: #188a42 !important;
    border-color: #188a42 !important;
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight.p-button-danger-dummy {
    background: #d9342b !important;
    border-color: #d9342b !important;
    color: #ffffff;
}

.even-row-bg {
    background: #d3d4d4 !important;
}

.event-started-bg {
    background: #e3f6f5 !important;
}

.p-button-label {
    font-weight: 400 !important;
}

.table-date-field {
    max-width: 90px !important;
    min-width: 90px !important;
}


.table-date-field-with-time {
    max-width: 150px !important;
    min-width: 150px !important;
}

.orderType {
    max-width: 75px !important;
    min-width: 75px !important;
}

.single-button {
    max-width: 30px !important;
    min-width: 30px !important;
}

.single-character-two-buttons {
    max-width: 50px !important;
    min-width: 50px !important;
}

.table-number-field {
    max-width: 75px !important;
    min-width: 70px !important;
}

.p-element.ng-invalid,
.p-component.ng-invalid {
    border: 1px solid red;
}


.hide-after-2-seconds {
    -moz-animation: cssAnimation 0s ease-in 2s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 2s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 2s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    to {
        width: 0;
        height: 0;
        overflow: hidden;
    }
}

@-webkit-keyframes cssAnimation {
    to {
        width: 0;
        height: 0;
        visibility: hidden;
    }
}

.modal {
    opacity: 0.5;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 0;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}